<script setup lang="ts">
import chatWindow from '@/ai/components/ChatWindow.vue';
import { setup } from '@/ai/views/chatView.setup';
import chatButtonAnimation from '@/ai/components/ChatButtonAnimation.vue';

const { position, isChatWindowOpen, onMouseDown, onMouseUp } = setup();
</script>

<template>
  <div
    :class="[isChatWindowOpen ? 'chat-view__close' : 'chat-view__open']"
    :style="{ top: position.top + 'px', left: position.left + 'px' }"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <ev-icon
      v-if="isChatWindowOpen"
      class="icon-close"
    />
    <chat-button-animation v-else />
  </div>
  <chat-window />
</template>

<style scoped lang="scss">
.chat-view__open,
.chat-view__close {
  display: flex;
  position: fixed;
  z-index: $on-chat-window;
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--ai-chat-float-btn-color);

  &.chat-view__close {
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 26px 0 var(--ai-chat-float-btn-shadow-color);

    .icon-close {
      background: linear-gradient(135deg, #3e99ff 2.86%, #6c4fff 97.14%);
    }
  }
}
</style>
