import { MaybeRefOrGetter, toValue } from 'vue';
import { ChartUnitInfo } from '@/common/utils/types';
import { DecimalPrecision } from '@/dashboard/components/widgetSettingsWindow/decimalsPrecisionSelect/decimalsPrecisionSelect.setup';
import { convertValueWithUnit } from './convertUnits.utils';

export const formatChartValueWithUnit = ({
  value,
  unit,
  decimal,
  toUnit = undefined,
  convertToLocaleString = false,
  preserveDecimalZeros = false,
}: {
  value: number;
  unit: string;
  decimal: number;
  toUnit?: string;
  convertToLocaleString?: boolean;
  preserveDecimalZeros?: boolean;
}): string => {
  const { value: convertedValue, unit: convertedUnit } = convertValueWithUnit(
    { value, unit },
    { decimal, toUnit },
  );
  const convertedvalueWithZeroOption = preserveDecimalZeros
    ? convertedValue.toFixed(decimal)
    : convertedValue;
  const localeValue = convertToLocaleString
    ? convertedValue.toLocaleString(undefined, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: preserveDecimalZeros ? decimal : undefined,
      })
    : convertedvalueWithZeroOption;
  const convertedUnitWithSpace = convertedUnit.includes(' ') ? convertedUnit : ` ${convertedUnit}`;
  return `${localeValue}${convertedUnitWithSpace}`;
};

export const formatYAxisWithUnit = ({
  value,
  unit,
  decimal = 3,
  showUnit = true,
  toUnit = undefined,
}: {
  value: number;
  unit: string;
  decimal?: number;
  showUnit?: boolean;
  toUnit?: string;
}): string => {
  if (!showUnit) {
    const { value: convertedValue } = convertValueWithUnit({ value, unit }, { decimal, toUnit });
    return `${convertedValue}`;
  }

  return formatChartValueWithUnit({ value, unit, decimal, toUnit });
};

export const formatTooltipWithUnit = ({
  value,
  unit,
  decimal = 3,
  preserveDecimalZeros = false,
}: {
  value: number;
  unit: string;
  decimal?: number;
  preserveDecimalZeros?: boolean;
}): string => {
  return formatChartValueWithUnit({
    value,
    unit,
    decimal,
    convertToLocaleString: true,
    preserveDecimalZeros,
  });
};

export const useEvChartTooltipFormatter = (
  unitList: MaybeRefOrGetter<ChartUnitInfo[]>,
  decimal?: MaybeRefOrGetter<DecimalPrecision | undefined>,
  preserveDecimalZeros?: MaybeRefOrGetter<boolean>,
  isHorizontal = false,
) => {
  const tooltipValueFormatter = ({ seriesId, x, y }) => {
    const value = isHorizontal ? x : y;
    const unit = toValue(unitList).find((unitInfo) => unitInfo.seriesId === seriesId)?.unit ?? '';
    if (unit) {
      return formatTooltipWithUnit({
        value,
        unit,
        decimal: toValue(decimal),
        preserveDecimalZeros: toValue(preserveDecimalZeros),
      });
    }
    return value.toLocaleString(undefined, {
      maximumFractionDigits: toValue(decimal),
      minimumFractionDigits: toValue(preserveDecimalZeros) ? toValue(decimal) : undefined,
    });
  };

  const markerValueFormatter = (value: number, unit?: string) => {
    if (unit && unit.toLowerCase() !== 'count') {
      return formatTooltipWithUnit({
        value,
        unit,
        decimal: toValue(decimal),
        preserveDecimalZeros: toValue(preserveDecimalZeros),
      });
    }
    return value.toLocaleString(undefined, {
      maximumFractionDigits: toValue(decimal),
      minimumFractionDigits: toValue(preserveDecimalZeros) ? toValue(decimal) : undefined,
    });
  };

  return {
    tooltipValueFormatter,
    markerValueFormatter,
  };
};
