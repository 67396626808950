import { generateUUID } from '@/common/utils/commonUtils';
import { cloneDeep, isNil, omit } from 'lodash-es';
import { getSafeSelectedTheme } from '@/common/utils/chartUtils';
import {
  FIXED_DATA_ID,
  DEFAULT_WIDGET_COLOR_OPTION,
  DEFAULT_WIDGET_SIZE,
  DEFAULT_WIDGET_TITLE_STYLE,
  DISPLAY_STYLE_KEY,
  WIDGET_CHART_TYPE_KEY,
} from '@/dashboard/utils/define';
import { BucketAggregationFactory } from '../components/widgetSettingsWindow/chartDataSettings/elasticsearch/bucketSettings/BucketAggregationFactory';
import { MetricsAggregationFactory } from '../components/widgetSettingsWindow/chartDataSettings/elasticsearch/metricsSettings/MetricsAggregationFactory';
import { useWidgetUUIDStore } from '../stores/widget-uuid';
import { applyDefaultChartOptions } from '../utils/chartOption.utils';
import { getWidgetCompName, isJsonType } from '../utils/dashboardUtils';
import { GridLayoutItem } from '../utils/types';
import {
  chartTypeMap,
  createTypeDtoMap,
  createTypeMap,
  dataResourceMap,
  displayStyleMap,
  labelFormatTypeMap,
  targetTagTypeMap,
  timePeriodMap,
  widgetDataTypeMap,
  widgetSeriesTypeMap,
  widgetTypeMap,
} from './widgets.datas';
import { WidgetInPort, WidgetOutPort } from './widgets.ports';

const convertTitleOption: WidgetInPort['convertTitleOption'] = (titleOptionDto) => {
  return titleOptionDto && isJsonType(titleOptionDto)
    ? JSON.parse(titleOptionDto)
    : {
        showTitle: true,
        titleText: '',
        titleStyle: cloneDeep(DEFAULT_WIDGET_TITLE_STYLE),
      };
};

const convertToWidgetColor: WidgetInPort['convertToWidgetColor'] = (widgetColorDto) => {
  return widgetColorDto && isJsonType(widgetColorDto)
    ? JSON.parse(widgetColorDto)
    : cloneDeep(DEFAULT_WIDGET_COLOR_OPTION);
};

const convertToChartOption: WidgetInPort['convertToChartOption'] = (chartOptionDto) => {
  return chartOptionDto && isJsonType(chartOptionDto) ? JSON.parse(chartOptionDto) : {};
};

const convertToCreateType: WidgetInPort['convertToCreateType'] = (createTypeDto) => {
  if (isNil(createTypeDto)) {
    return 'base';
  }
  const createType = createTypeMap[createTypeDto];
  if (!createType) {
    throw new Error(`${createTypeDto} createType이 추가되었습니다.`);
  }

  return createType;
};

const convertToFilterOption: WidgetInPort['convertToFilterOption'] = (filterOptionDto) => {
  return filterOptionDto && isJsonType(filterOptionDto) ? JSON.parse(filterOptionDto) : undefined;
};

const convertToCalendarTimeRange: WidgetInPort['convertToCalendarTimeRange'] = (
  calendarTimeRangeDto,
) => {
  return calendarTimeRangeDto && isJsonType(calendarTimeRangeDto)
    ? JSON.parse(calendarTimeRangeDto)
    : undefined;
};

const convertToDataResource: WidgetInPort['convertToDataResource'] = (dataResourceDto) => {
  return dataResourceMap[dataResourceDto ?? 'exemOne'];
};

const convertToWidgetType: WidgetInPort['convertToWidgetType'] = (widgetTypeDto) => {
  if (!widgetTypeDto) {
    return 'GRAPHS';
  }
  const widgetType = widgetTypeMap[widgetTypeDto];
  if (!widgetType) {
    throw new Error(`${widgetTypeDto} widgetType이 추가되었습니다.`);
  }

  return widgetType;
};

const convertToChartType: WidgetInPort['convertToChartType'] = (chartTypeDto) => {
  if (!chartTypeDto) {
    return 'TIME_SERIES';
  }
  const chartType = chartTypeMap[chartTypeDto];
  if (!chartType) {
    throw new Error(`${chartTypeDto} chartType이 추가되었습니다.`);
  }

  return chartType;
};

const convertToDisplayStyle: WidgetInPort['convertToDisplayStyle'] = (displayStyleDto) => {
  if (isNil(displayStyleDto) || displayStyleDto === '') {
    return '';
  }
  const displayStyle = displayStyleMap[displayStyleDto];
  if (!displayStyle) {
    throw new Error(`${displayStyleDto} displayStyle이 추가되었습니다.`);
  }

  return displayStyle;
};

const convertToTimePeriod: WidgetInPort['convertToTimePeriod'] = (timePeriodDto) => {
  if (isNil(timePeriodDto)) {
    return undefined;
  }
  const timePeriod = timePeriodMap[timePeriodDto];
  if (!timePeriod) {
    throw new Error(`${timePeriodDto} timePeriod이 추가되었습니다.`);
  }

  return timePeriod;
};

const convertToWidgetSeriesType: WidgetInPort['convertToWidgetSeriesType'] = (seriesTypeDto) => {
  if (isNil(seriesTypeDto)) {
    return undefined;
  }
  const seriesType = widgetSeriesTypeMap[seriesTypeDto];
  if (!seriesType) {
    throw new Error(`${seriesTypeDto} seriesType이 추가되었습니다.`);
  }

  return seriesType;
};

const convertToWidgetDataType: WidgetInPort['convertToWidgetDataType'] = (dataTypeDto) => {
  if (isNil(dataTypeDto)) {
    return undefined;
  }
  const dataType = widgetDataTypeMap[dataTypeDto];
  if (!dataType) {
    throw new Error(`${dataTypeDto} dataType이 추가되었습니다.`);
  }

  return dataType;
};

const convertToTargetTagType: WidgetInPort['convertToTargetTagType'] = (tagTypeDto) => {
  const tagType = targetTagTypeMap[tagTypeDto];
  if (!tagType) {
    throw new Error(`${tagTypeDto} tagType이 추가되었습니다.`);
  }

  return tagType;
};

const covertToChartData: WidgetInPort['covertToChartData'] = (chartDataDto) => {
  const parsedExtraResourceOption = JSON.parse(chartDataDto.extraResourceOption ?? '{}');

  return {
    ...omit(chartDataDto, 'extraResourceOption'),
    id: generateUUID(),
    category: chartDataDto.category ?? '',
    seriesType:
      chartDataDto.dataId === FIXED_DATA_ID.TOPOLOGY
        ? 'byTarget'
        : convertToWidgetSeriesType(chartDataDto.seriesType),
    dataType: convertToWidgetDataType(chartDataDto.dataType),
    targets: chartDataDto.targets.map((target) => ({
      ...target,
      tagType: convertToTargetTagType(target.tagType),
      tagValue: target.tagValueId ?? '',
    })),
    value:
      chartDataDto.value && isJsonType(chartDataDto.value) ? JSON.parse(chartDataDto.value) : '',
    externalOptions: {
      ...omit(parsedExtraResourceOption, 'type', 'legend', 'query', 'buckets', 'metrics'),
      labelFormatType: labelFormatTypeMap[parsedExtraResourceOption.labelFormatType ?? 'labelKey'],
      labelFormat: parsedExtraResourceOption.labelFormat ?? '',
      query: parsedExtraResourceOption.query ?? '',
      buckets: parsedExtraResourceOption.buckets?.map((bucket) => {
        const bucketAggregation = BucketAggregationFactory.createBucketAggregation(bucket.type);
        bucketAggregation.configure(bucket.config);
        return bucketAggregation;
      }),
      metrics: parsedExtraResourceOption.metrics?.map((metric) => {
        const metricAggregation = MetricsAggregationFactory.createMetricsAggregation(metric.type);
        metricAggregation.configure(metric.config);
        return metricAggregation;
      }),
    },
  };
};

const convertToExtraData: WidgetInPort['convertToExtraData'] = (extraDataDto) => {
  return extraDataDto?.map((extraData) => ({
    extraId: extraData.extraId ?? '',
    targets:
      extraData.targets?.map((target) =>
        target.map((item) => ({
          id: item.id ?? '',
          value: item.value ?? '',
        })),
      ) ?? [],
  }));
};

const convertToGridLayoutItem: WidgetInPort['convertToGridLayoutItem'] = (widgetChildValue) => {
  const chartType = convertToChartType(widgetChildValue.chartType);
  const displayStyle = convertToDisplayStyle(widgetChildValue.displayStyle);

  const titleOption = convertTitleOption(widgetChildValue.titleOption);

  const widgetSize = DEFAULT_WIDGET_SIZE[chartType][displayStyle];

  const { generateWidgetUUID } = useWidgetUUIDStore();

  return {
    i: widgetChildValue.widgetUUID ?? generateWidgetUUID(),
    x: widgetChildValue.x ?? 0,
    y: widgetChildValue.y ?? 0,
    w: widgetChildValue.width ?? 1,
    h: widgetChildValue.height ?? 1,
    widgetMappingId: widgetChildValue.widgetMappingId,
    compName: getWidgetCompName(WIDGET_CHART_TYPE_KEY[chartType], DISPLAY_STYLE_KEY[displayStyle]),
    dataResource: convertToDataResource(widgetChildValue.dataResourceType),
    widgetType: convertToWidgetType(widgetChildValue.widgetType),
    chartType,
    titleOption: convertTitleOption(widgetChildValue.titleOption),
    widgetColor: convertToWidgetColor(widgetChildValue.widgetColor),
    createType: convertToCreateType(widgetChildValue.createType),
    displayStyle,
    headerType: titleOption.headerType ?? 'basic',
    timePeriod: convertToTimePeriod(widgetChildValue.period),
    chartData: widgetChildValue.chartData?.map(covertToChartData),
    colorTheme: getSafeSelectedTheme(widgetChildValue.colorTheme),
    minW: widgetSize?.minWidth,
    minH: widgetSize?.minHeight,
    chartOption: applyDefaultChartOptions(
      chartType,
      convertToChartOption(widgetChildValue.chartOption),
    ),
    filterOption: convertToFilterOption(widgetChildValue.filterOption),
    calendarTimeRange: convertToCalendarTimeRange(widgetChildValue.value),
    customId: widgetChildValue.customId,
    isInViewport: false,
    originHeight: undefined,
    extraData: convertToExtraData(widgetChildValue.extraData),
  };
};

export const convertListToGridLayoutItem: WidgetInPort['convertListToGridLayoutItem'] = (
  widgetGroupValue,
) => {
  const setGroup = (parent: GridLayoutItem) => {
    const childrenGridLayout = widgetGroupValue
      .filter((widget) => widget.parentUUID === parent?.i && !!widget.widgetUUID)
      .map((child) => setGroup(convertToGridLayoutItem(child)));

    return parent ? { ...parent, children: childrenGridLayout } : null;
  };

  const parentWidget = widgetGroupValue.find((widget) => isNil(widget.parentUUID));
  return parentWidget ? setGroup(convertToGridLayoutItem(parentWidget)) : null;
};

export const convertToGridLayoutItemList: WidgetInPort['convertToGridLayoutItemList'] = (
  widgetGroupValue,
) => {
  return widgetGroupValue.map(convertListToGridLayoutItem);
};

const convertToCreateTypeDto: WidgetOutPort['convertToCreateTypeDto'] = (createType) => {
  const createTypeDto = createTypeDtoMap[createType];
  if (!createTypeDto) {
    throw new Error(`${createType} createType을 추가해주세요.`);
  }

  return createTypeDto;
};

const convertToExtraDataDto: WidgetOutPort['convertToExtraDataDto'] = (extraData) => {
  return extraData?.map((data) => ({
    extraId: data.extraId,
    targets: data.targets.map((target) =>
      target.map((item) => ({
        id: item.id,
        value: item.value,
      })),
    ),
  }));
};

const convertToWidgetGroupValueResponseV8: WidgetOutPort['convertToWidgetGroupValueResponseV8'] = (
  gridLayoutItem,
  parentUUID,
) => {
  return {
    widgetUUID: gridLayoutItem.i,
    parentUUID: parentUUID ?? undefined,
    x: gridLayoutItem.x,
    y: gridLayoutItem.y,
    width: gridLayoutItem.w,
    height: gridLayoutItem.h,
    widgetType: gridLayoutItem.widgetType,
    chartType: gridLayoutItem.chartType,
    displayStyle: gridLayoutItem.displayStyle,
    titleOption: JSON.stringify({
      ...gridLayoutItem.titleOption,
      headerType: gridLayoutItem.headerType,
    }),
    widgetColor: JSON.stringify(gridLayoutItem.widgetColor),
    chartOption: JSON.stringify(gridLayoutItem.chartOption),
    widgetMappingId: gridLayoutItem.widgetMappingId,
    filterOption:
      gridLayoutItem.filterOption != null ? JSON.stringify(gridLayoutItem.filterOption) : undefined,
    period: gridLayoutItem.timePeriod,
    value: gridLayoutItem.calendarTimeRange ? JSON.stringify(gridLayoutItem.calendarTimeRange) : '',
    colorTheme: gridLayoutItem.colorTheme,
    customId: gridLayoutItem.customId,
    chartData:
      gridLayoutItem.chartData?.map((chartData) => ({
        ...omit(chartData, 'externalOptions'),
        targets: chartData.targets.map((target) => ({
          ...target,
          tagValueId: target.tagValue,
        })),
        value: chartData.value ? JSON.stringify(chartData.value) : '',
        extraResourceOption: chartData.externalOptions
          ? JSON.stringify(chartData.externalOptions)
          : undefined,
      })) ?? [],
    createType: convertToCreateTypeDto(gridLayoutItem.createType),
    extraData: convertToExtraDataDto(gridLayoutItem.extraData),
    dataResourceType: gridLayoutItem.dataResource,
  };
};

export const convertToWidgetGroupValueResponseV8Group: WidgetOutPort['convertToWidgetGroupValueResponseV8Group'] =
  (gridLayoutItem, parentUUID) => {
    const { children } = gridLayoutItem;
    if (children && children.length > 0) {
      const exceptChild = omit(gridLayoutItem, 'children');
      return [
        convertToWidgetGroupValueResponseV8(exceptChild, parentUUID),
        ...children.flatMap((child) =>
          convertToWidgetGroupValueResponseV8Group(child, gridLayoutItem.i),
        ),
      ];
    }
    return [convertToWidgetGroupValueResponseV8(gridLayoutItem, parentUUID)];
  };

export const convertToWidgetGroupValueResponseV8List: WidgetOutPort['convertToWidgetGroupValueResponseV8List'] =
  (gridLayoutItemList) => {
    return gridLayoutItemList.map((item) => convertToWidgetGroupValueResponseV8Group(item, null));
  };
