<script setup lang="ts">
import { Props, setup } from '@/ai/components/targetList.setup';
import BaseGrid from '@/common/components/molecules/grid/BaseGrid.vue';

const props = defineProps<Props>();
const {
  toggleValue,
  onChange,
  t,
  convertedRows,
  columns,
  onDetail,
  getIconType,
  mappedTargetData,
} = setup(props);
</script>

<template>
  <transition name="fade">
    <div
      v-if="true"
      class="target-list__wrapper"
    >
      <div class="target-list__header">
        <ev-button-group class="target-button--group">
          <ev-button
            class="target-button"
            :class="{ selected: toggleValue === 'list' }"
            :type="toggleValue === 'list' ? 'primary' : 'default'"
            @click="() => onChange('list')"
          >
            <ev-icon icon="icon-list" />
            <p class="list-text">{{ t('WORD.LIST') }}</p>
          </ev-button>
          <ev-button
            class="target-button"
            :class="{ selected: toggleValue === 'icon' }"
            :type="toggleValue === 'icon' ? 'primary' : 'default'"
            @click="() => onChange('icon')"
          >
            <ev-icon icon="icon-icon" />
            <p class="list-text">{{ t('WORD.ICON') }}</p>
          </ev-button>
        </ev-button-group>
      </div>
      <div class="target-list__content">
        <div v-if="toggleValue === 'list'">
          <base-grid
            :columns="columns"
            :rows="convertedRows"
            class="list__wrapper"
          >
            <template #platform="{ item }">
              <div
                :key="item"
                class="list--text"
              >
                <ev-icon :icon="getIconType(item.row[2][0])" />
                {{ item.row[2][0] }}
              </div>
            </template>
            <template #targetName="{ item }">
              <div
                :key="item"
                class="list--text"
                @click="() => onDetail(item.row[2])"
              >
                {{ item.row[2][1] }}
              </div>
            </template>
          </base-grid>
        </div>
        <div v-else-if="toggleValue === 'icon'">
          <transition-group
            name="fade"
            tag="div"
          >
            <div
              v-for="(targets, category) in mappedTargetData"
              :key="category"
            >
              <div class="icon__header">{{ category }} ({{ targets.length }})</div>
              <div class="icon__wrapper">
                <div
                  v-for="(target, index) in targets"
                  :key="index"
                  class="card-box"
                  @click="() => onDetail(target)"
                >
                  <div :class="['target-box', target.targetAlertLevel.toLocaleLowerCase()]">
                    <ev-icon
                      :icon="getIconType(target.targetKind)"
                      class="target-icon"
                    />
                  </div>
                  <span :title="target.targetName">{{ target.targetName }}</span>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.target-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: var(--ai-chat-bubble-bg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .target-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 2px;
    }

    .target-icon {
      width: 22px;
      height: 22px;
      background-color: var(--ai-chat-target-icon-color);
    }

    .normal {
      border: 1px solid var(--ai-target-normal-border-color);
      background-color: var(--ai-target-normal-bg-color);
    }

    .warning {
      border: 1px solid var(--ai-target-warning-border-color);
      background-color: var(--ai-target-warning-bg-color);
    }

    .critical {
      border: 1px solid var(--ai-target-critical-border-color);
      background-color: var(--ai-target-critical-bg-color);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .target-button--group {
      display: flex;
    }
    .target-button {
      display: flex;
      align-items: center;
      width: 53px !important;
      height: 20px !important;
      padding: 5px 2px 6px 0 !important;
      cursor: pointer;
      transition: background-color 0.3s ease;

      p {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .list {
      &--text {
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: var(--ai-target-list-text-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .icon {
      &__header {
        align-items: center;
        padding: 12px 12px 8px;
        border: 1px solid var(--ai-chat-target-icon-border-color);
        background-color: var(--ai-target-icon-header-bg-color);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
      }

      &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(78px, 1fr));
        gap: 8px;
        padding: 12px;
        border: 1px solid var(--ai-chat-target-icon-border-color);
        transition: background-color 0.3s ease;

        .card-box {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;
          justify-content: center;
          width: 81px;
          height: 92px;
          padding: 0 4px;
          border: 1px solid var(--ai-chat-target-icon-border-color);
          border-radius: 2px;
          background-color: var(--ai-chat-bubble-bg);

          &:hover {
            border: 1px solid var(--ai-target-icon-hover-border-color);
          }
          &:active {
            background-color: var(--ai-target-icon-hover-bg-color);
          }
        }

        span {
          width: 100%;
          overflow: hidden;
          font-size: 12px;
          font-weight: 400;
          color: var(--ai-target-list-text-color);
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .target-icon-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(79px, 1fr));
      gap: 8px;
      padding: 12px;
      border: 1px solid var(--ai-chat-target-icon-border-color);
    }
  }
}
</style>
