import { computed, ComputedRef, onDeactivated, onUnmounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useRolePermission } from '@/common/permission/permission.utils';
import {
  CurrentDashboardDetailPermission,
  DetailTypeToPermissionKey,
  type SlideTabPanelInfo,
  TOTAL_DETAIL,
  useSlideDetailStore,
  useSplitSlideDetailStore,
} from '@/common/stores/slide-detail';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { getLocaleKey } from '@/common/utils/commonUtils';
import type { RolePermissionKeyType } from '@/config/views/permission/rolePermission/rolePermission.type';

export interface Props {
  isVisible: boolean;
  isLoading?: boolean;
  hasExpand?: boolean;
}

export interface Emit {
  (e: 'update:is-visible', value: boolean): void;
}

export const setup = (props: Props, emit: Emit) => {
  const route = useRoute();
  const { t } = useInternational();
  const slideDetailStore = useSlideDetailStore();
  const {
    initTabPanel,
    setSelectedSlideTabPanel,
    addSlideDetailHistory,
    deleteLastSlideDetailHistory,
    setOpened,
    deleteSlideTabPanel,
    onClickExcess,
  } = slideDetailStore;
  const {
    isDetailLoading,
    detailComp,
    selectedSlideTabPanel,
    slideTabPanel,
    slideDetailHistory,
    isOpened,
    isExcess,
  } = storeToRefs(slideDetailStore);

  const splitSlideDetailStore = useSplitSlideDetailStore();
  const { initSplitTabPanel } = splitSlideDetailStore;
  const {
    isOpened: isSplitOpened,
    detailComp: splitDetailComp,
    isDetailLoading: isSplitLoading,
    selectedSlideTabPanel: splitTabPanel,
  } = storeToRefs(splitSlideDetailStore);

  const { isPermissionDenied } = useRolePermission();
  const isDetailSlidePermissionDenied = (rolePermissionKey: RolePermissionKeyType) => {
    return isPermissionDenied({
      type: 'detailSlide',
      showError: false,
      rolePermissionKey,
    });
  };

  const isShow = computed({
    get: () => props.isVisible,
    set: (value) => {
      emit('update:is-visible', value);
    },
  });

  const isOpenNav = ref(false);

  const isShowSplit = computed(() => {
    return isSplitOpened.value && !!splitDetailComp.value;
  });

  const closeSplit = () => {
    initSplitTabPanel();
  };

  const onSlideTabChange = (item: SlideTabPanelInfo, isHistoryBack = false) => {
    if (item.value === selectedSlideTabPanel.value?.value) return;

    setSelectedSlideTabPanel(item);

    if (!isHistoryBack) {
      addSlideDetailHistory(item);
    }
  };

  const onNavToggle = () => {
    isOpenNav.value = !isOpenNav.value;
  };

  const onHistoryBack = () => {
    if (slideDetailHistory.value.length === 1) return;

    deleteLastSlideDetailHistory();
    const lastHistory = slideDetailHistory.value[slideDetailHistory.value.length - 1];
    onSlideTabChange(lastHistory, true);
  };

  const getLocalizedTitle = ({ type, name }: { type?: string; name?: string } = {}) => {
    const exactType = (type || '').replace(' List', '');
    let localizedType = exactType ? t(`WORD.${getLocaleKey(exactType)}`, exactType) : exactType;
    const detailItemName = name ? `: ${name}` : '';
    if (localizedType === TOTAL_DETAIL.BROWSER_ERROR_TRACKING) {
      return t('WORD.BROWSER_ERROR_ANALYSIS');
    }
    if (localizedType === TOTAL_DETAIL.WEB_APPLICATION) {
      localizedType = t('WORD.APPLICATION');
    }
    if (localizedType === TOTAL_DETAIL.USER_MONITORING_SESSION_ANALYSIS) {
      return t('WORD.SESSION_ANALYSIS');
    }
    if (localizedType === TOTAL_DETAIL.USER_MONITORING_SESSION_REPLAY) {
      return t('WORD.SESSION_REPLAY');
    }
    if (localizedType === TOTAL_DETAIL.USER_MONITORING_USER_EVENT) {
      return name ?? t('WORD.USER_EVENT');
    }
    if (localizedType === TOTAL_DETAIL.SQL) {
      return name?.startsWith('Query ID') || name?.startsWith('SQL ID')
        ? name ?? ''
        : `${localizedType}${detailItemName}`;
    }
    if (localizedType === TOTAL_DETAIL.SHARDED_CLUSTER) {
      localizedType = `${t('WORD.SHARDED')} ${t('WORD.CLUSTER')}`;
    }
    if (type === TOTAL_DETAIL.ALERT_HISTORY) {
      return `${t('WORD.HOME.TOTAL_ALERT')}`;
    }

    return `${localizedType}${detailItemName}`;
  };

  const localizedTitle: ComputedRef<string> = computed(() => {
    const { type, name, title } = selectedSlideTabPanel.value || {};
    return title || getLocalizedTitle({ type, name });
  });

  const getNavTitle = ({
    type,
    name,
    title,
  }: { type?: string; name?: string; title?: string } = {}): string =>
    title || getLocalizedTitle({ type, name });

  const closeSlide = () => {
    initTabPanel();
    isOpenNav.value = false;
    isShow.value = false;
  };

  watch(
    () => props.isVisible,
    (isVisible) => {
      if (isVisible) {
        const rolePermissionKey = selectedSlideTabPanel.value?.type
          ? DetailTypeToPermissionKey[selectedSlideTabPanel.value.type]
          : undefined;
        if (
          !rolePermissionKey ||
          isDetailSlidePermissionDenied(
            selectedSlideTabPanel.value?.rolePermissionKey || rolePermissionKey,
          ) ||
          (route.query.rootMenu &&
            isDetailSlidePermissionDenied(
              CurrentDashboardDetailPermission[`${route.query.rootMenu}`],
            ))
        ) {
          console.error('Permission Denied.');
          isShow.value = false;
          return;
        }
      }

      setOpened(isVisible);
      if (!isVisible) {
        closeSlide();
      }
    },
  );

  watch(isOpened, (value) => {
    isShow.value = value;
  });

  onUnmounted(() => {
    setOpened(false);
  });

  onDeactivated(() => {
    isShow.value = false;
  });

  return {
    isShow,
    isOpenNav,
    isDetailLoading,
    detailComp,
    selectedSlideTabPanel,
    slideTabPanel,
    slideDetailHistory,
    onSlideTabChange,
    onNavToggle,
    onHistoryBack,
    localizedTitle,
    getNavTitle,
    t,
    closeSlide,
    getLocalizedTitle,
    deleteSlideTabPanel,
    isExcess,
    onClickExcess,

    isShowSplit,
    splitDetailComp,
    isSplitLoading,
    splitTabPanel,
    closeSplit,
  };
};
