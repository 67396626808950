<script setup lang="ts">
import { setup } from '@/ai/components/chatButtonAnimation.setup';

const { container } = setup();
</script>

<template>
  <div
    ref="container"
    class="chat-button-animation"
  >
    <i class="icon-ai-bubble" />
  </div>
</template>

<style scoped lang="scss">
.chat-button-animation {
  position: relative;
  width: 60px;
  height: 60px;

  .icon-ai-bubble {
    position: absolute;
    right: 35px;
    bottom: 25px;
    width: 126px;
    height: 68px;
    opacity: 0;
    pointer-events: none;
    background: url('@/common/assets/ai/ai-bubble-light.svg') no-repeat center;
    background-size: 100% auto !important;
    transition: opacity 0.3s ease;
    [data-theme='dark'] & {
      background: url('@/common/assets/ai/ai-bubble-dark.svg') no-repeat center;
      background-size: 100% auto !important;
    }
  }

  &:hover .icon-ai-bubble {
    opacity: 1;
  }
}
</style>
