<script setup lang="ts">
import { defineProps } from 'vue';
import { ICON_TYPE, GREETING_COLOR, GREETING_HOVER_COLOR } from '@/ai/utils/defined';
import TargetList from '@/ai/components/TargetList.vue';
import { type Props, setup, type Emit } from './botMessage.setup';

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const { parsedResult, handleClick, container, parsedMessage } = setup(props, emit);
</script>

<template>
  <div class="bot-message__wrapper">
    <div
      v-if="message.messageType === 'welcomeText'"
      class="welcome-message__container"
    >
      <div class="bot-message__animation">
        <div
          ref="container"
          class="bot-animation"
        />
        <div
          class="bot-message"
          v-html="parsedMessage"
        />
      </div>
      <div class="welcome-message__content">
        <div
          v-for="(item, index) in parsedResult"
          :key="index"
          class="bot-button__container"
          :style="{
            '--color': GREETING_COLOR[index],
            '--hover-color': GREETING_HOVER_COLOR[index],
          }"
          @click="handleClick(item.message)"
        >
          <div class="icon-box">
            <ev-icon
              class="bot-button__icon"
              :icon="`icon-${ICON_TYPE[index]}`"
              :style="{ '--color': GREETING_COLOR[index] }"
            />
          </div>

          <div class="bot-button__text">{{ item.message }}</div>
          <ev-icon
            icon="ev-icon-allow2-right2"
            class="line-arrow-right"
            :style="{ '--color': GREETING_COLOR[index] }"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="general-message__container"
    >
      <i class="general-message__icon" />
      <div class="general-message__content">
        <img
          v-if="message.imageUrl"
          class="widget-message__wrapper"
          :src="message.imageUrl"
          alt="Capture Widget"
        />
        <div
          v-if="message.message"
          class="text-message__wrapper"
          @mousedown.stop
          v-html="parsedMessage"
        />
        <div
          v-if="message.resultType === 'textButton'"
          class="button-message__wrapper"
        >
          <div
            v-for="(item, index) in parsedResult"
            :key="index"
            class="button-message__container"
            @click="handleClick(item.message)"
          >
            <div class="button-message__text">{{ item.message }}</div>
            <ev-icon
              icon="ev-icon-allow2-right2"
              class="line-arrow-right"
            />
          </div>
        </div>
        <div
          v-if="message.resultType === 'targetList'"
          class="target-message__wrapper"
        >
          <target-list :target-data="parsedResult" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.bot-message__wrapper {
  display: flex;
  flex-direction: column;

  .line-arrow-right {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  .welcome-message__container {
    flex-direction: column;
    width: 382px;
    margin: 0 auto;

    .bot-message__animation {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .bot-animation {
      align-self: end;
      width: 110px;
      height: 140px;
    }

    .bot-message {
      margin-bottom: 34px;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: var(--ai-chat-bot-message-color);
    }
  }

  .welcome-message__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    .icon-box {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin-right: 12px;
      border-radius: 8px;
      background-color: var(--ai-chat-bot-greeting-icon-box-bg-color);
    }

    .bot-button__icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin: 4px;
      background-color: var(--color);
      mask-repeat: no-repeat;
      mask-size: contain;
    }

    .bot-button__text {
      flex: 1;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: var(--ai-chat-greeting-message-color);
    }

    .line-arrow-right {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
  }

  .bot-button__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 8px;
    background: var(--ai-chat-bubble-bg);
    box-shadow: 0 10px 26px 0 var(--ai-chat-bubble-shadow-color);
  }

  .bot-button__container:hover {
    border-color: var(--color);
    .bot-button__text {
      color: var(--hover-color);
    }

    .line-arrow-right {
      color: var(--hover-color);
    }
  }

  .general-message__container {
    display: flex;
    .general-message__icon {
      width: 44px;
      height: 44px;
      margin-right: 16px;
      background-image: url('@/common/assets/ai/chat-bot.svg');
    }

    .general-message__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 75%;
    }

    .widget-message__wrapper {
      width: 75%;
      margin-bottom: 8px;
      overflow: hidden;
      border-radius: 8px;
      background: var(--ai-chat-bubble-bg);
      box-shadow: 0 10px 26px 0 var(--ai-chat-bubble-shadow-color);
    }
  }

  .text-message__wrapper {
    width: fit-content;
    max-width: 75%;
    padding: 12px;
    border-radius: 8px;
    background: var(--ai-chat-bubble-bg);
    box-shadow: 0 10px 26px 0 var(--ai-chat-bubble-shadow-color);
    font-size: 13px;
    font-weight: 400;

    img {
      width: 100%;
    }
  }

  .button-message__wrapper {
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 8px;
    background: var(--ai-chat-bubble-bg);
    box-shadow: 0 10px 26px 0 var(--ai-chat-bubble-shadow-color);
    font-size: 11px;
    font-weight: 500;
    color: var(--ai-chat-bot-btn-message-hover-color);
  }

  .button-message__wrapper:hover {
    color: var(--ai-chat-bot-message-no-hover-color);
    .line-arrow-right {
      color: var(--ai-chat-bot-message-no-hover-color);
    }
  }

  .button-message__container {
    display: flex;
    justify-content: space-between;
    padding: 18px 10px;
    cursor: pointer;
    border-bottom: 1px solid var(--ai-chat-bot-message-line-color);

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  .button-message__container:hover {
    color: var(--ai-chat-bot-message-hover-color);
    .line-arrow-right {
      color: var(--ai-chat-bot-message-hover-color);
    }
  }
}
</style>
